import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import { assetLink } from 'src/utils';
import SectionWithVideoOverlap from 'src/sections/shared/SectionWithVideoOverlap';
import Typography from 'src/components/core/Typography';

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
  },
  title: {
    maxWidth: pxToRem(550),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(10),
    [breakpoint.down('md')]: {
      marginTop: spacing(5),
    },
  },
  underline: {
    marginBottom: spacing(12),
    [breakpoint.down('md')]: {
      marginBottom: spacing(5),
    },
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: spacing(10),
    rowGap: spacing(5),
    gridTemplateColumns: `repeat(3, minmax(0, ${pxToRem(468)}))`,
    [breakpoint.mobile()]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginTop: spacing(10),
    },
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoint.mobile()]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  icon: {
    height: pxToRem(60),
    [breakpoint.mobile()]: {
      height: 'auto',
      width: pxToRem(60),
      minWidth: pxToRem(60),
      marginRight: spacing(6),
      marginTop: spacing(2),
    },
  },
  label: {
    marginTop: spacing(6),
    textAlign: 'center',
    [breakpoint.mobile()]: {
      textAlign: 'left',
      marginTop: 0,
    },
  },
  details: {
    marginTop: spacing(2),
    textAlign: 'center',
    [breakpoint.mobile()]: {
      textAlign: 'left',
    },
  },
});

const content = [
  {
    iconSrc: assetLink('ShoppingIconAlt.svg'),
    label: 'Enter the digital age',
    details: 'Every small business should use a modern approach. This means having everything from an online presence on social media to offering your customers multiple payment methods.',
  },
  {
    iconSrc: assetLink('PeopleIcon.svg'),
    label: 'Grow your customer base',
    details: 'Whatever your reach is, we can triple how many customers reach your site. We’ll ultimately make your businesses more searchable, clickable, and shoppable!',
  },
  {
    iconSrc: assetLink('TimeIcon.svg'),
    label: 'We\'re your assistant',
    details: '56% of businesses struggle to hire the right team to help bring your passion to life. Let us take that responsibility off of your shoulders. We handle the tech stuff while you focus on what you do best.',
  },
];

const RunYourBusiness: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionWithVideoOverlap className={classes.container}>
      <Typography variant="h2" color="blue" underline centered className={classes.title} underlineClassName={classes.underline}>
        Run your business, we&apos;ll handle the rest.
      </Typography>
      <div className={classes.grid}>
        {content.map(({ iconSrc, label, details }) => (
          <div className={classes.gridItem} key={label}>
            <img src={iconSrc} alt={`${label} Icon`} className={classes.icon} />
            <div>
              <Typography variant="h3" color="blue" className={classes.label}>
                {label}
              </Typography>
              <Typography variant="p1" color="textSecondary" className={classes.details}>
                {details}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </SectionWithVideoOverlap>
  );
};

export default RunYourBusiness;
