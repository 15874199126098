import React from 'react';
import cx from 'clsx';
import { pxToRem, spacing, breakpoint } from 'src/theme';
import { useSectionAboveStyles } from 'src/sections/shared/SectionWithVideoOverlap';
import SlantedSection from 'src/sections/shared/SlantedSection';
import { useBreakpointToggleStyles } from 'src/hooks';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import { createUseStyles } from 'react-jss';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import { useHeroCopy } from './utils';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    height: pxToRem(650),
    minHeight: pxToRem(650),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  contentGradient: {
    background: 'radial-gradient(153.68% 510.76% at 153.68% 79.94%, #3D78B8 0%, #13266D 92.07%), #13296B',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(107.95deg, #13266D -12.75%, #5FBCFF 253.76%), #13296B',
    },
  },
  content: {
    width: '100%',
    maxWidth: pxToRem(640),
    paddingLeft: spacing(6),
    paddingRight: spacing(6),
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [breakpoint.down('md')]: {
      paddingLeft: spacing(6),
      paddingRight: spacing(6),
      paddingTop: spacing(2),
      marginLeft: 0,
    },
  },
  button: {
    marginTop: spacing(17),
    marginBottom: spacing(10),
    [breakpoint.down('md')]: {
      marginTop: spacing(10),
    },
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  const videoPaddingClasses = useSectionAboveStyles();
  const breakpointToggleStyles = useBreakpointToggleStyles();
  const {
    copy,
    loading,
  } = useHeroCopy();
  const content = loading ? (
    <div className={cx(classes.content, videoPaddingClasses.root)} style={{ width: '100%' }}>
      <NavbarSpacer className={breakpointToggleStyles.aboveMd} />
      <div className="animate-pulse" style={{ width: '100%' }}>
        <div className="bg-gray-skeleton rounded-md" style={{ width: '100%', height: pxToRem(78) }} />
        <br />
        <div className="bg-gray-skeleton rounded-md" style={{ width: '100%', height: pxToRem(78) }} />
        <div className={cx('bg-gray-skeleton rounded-md my-17', classes.button)} style={{ width: pxToRem(191), height: pxToRem(46) }} />
      </div>
    </div>
  ) : (
    <div className={cx(classes.content, videoPaddingClasses.root)}>
      <NavbarSpacer className={breakpointToggleStyles.aboveMd} />
      <Typography variant="h1" color="white">
        {copy.headline1}
        <br />
        {copy.headline2}
      </Typography>
      <Button href="/register/" variant="filled" color="teal" className={classes.button}>
        {copy.ctaBtn}
      </Button>
    </div>
  );
  return (
    <SlantedSection
      img={SectionImages[7]}
      contentWidth={0.7}
      className={classes.container}
      showNavbarGradient
      contentClassName={classes.contentGradient}
    >
      {content}
    </SlantedSection>
  );
};

export default Hero;
