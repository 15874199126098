import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  spacing, breakpoint, Theme, pxToRem,
} from 'src/theme';
import SlantedSection from 'src/sections/shared/SlantedSection';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles((theme: Theme) => ({
  content: {
    padding: spacing(15),
    [breakpoint.down('md')]: {
      padding: spacing(10),
    },
  },
  contentBackground: {
    background: theme.palette.secondary.LIGHT_BLUE,
  },
  button: {
    marginTop: spacing(10),
    [breakpoint.down('md')]: {
      marginTop: spacing(5),
    },
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
}));

const NextLevelSection: React.FC = () => {
  const classes = useStyles();
  return (
    <SlantedSection
      img={SectionImages[9]}
      imgLeft
      slantAmount={0.1}
      contentWidth={0.5}
      contentClassName={classes.contentBackground}
    >
      <div className={classes.content}>
        <Typography
          variant="h2"
          color="blue"
          style={{ maxWidth: pxToRem(450) }}
        >
          $100 To Take Your Business To The Next Level
        </Typography>
        <Button href="/register/" variant="filled" color="teal" className={classes.button}>
          Claim My $100
        </Button>
      </div>
    </SlantedSection>
  );
};

export default NextLevelSection;
