import React, { useState, useEffect, useRef } from 'react';
import cx from 'clsx';
import { createUseStyles } from 'react-jss';
import {
  pxToRem, spacing, breakpoint, useIsMobile,
} from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import WithUnderline from 'src/components/core/WithUnderline';
import IconButton from 'src/components/core/IconButton';
import Clickable from 'src/components/core/Clickable';
import { assetLink } from 'src/utils';
import { useIsVisible, useScrollableRef } from 'src/hooks';
import { StaticImage } from 'gatsby-plugin-image';

interface StyleProps {
  variant: Variant,
}

const useStyles = createUseStyles<string, StyleProps>({
  containerDark: {
    background: 'linear-gradient(288.79deg, #13266D 0.18%, #5FBCFF 144.79%)',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(179.79deg, #13266D 30.39%, #5FBCFF 153.01%), #F7F7F7',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  videoContainer: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    maxWidth: pxToRem(600),
    width: '50%',
    [breakpoint.down('md')]: {
      width: '100%',
      marginBottom: spacing(10),
    },
  },
  chapterContainer: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(4),
    },

    width: '50%',
    [breakpoint.down('md')]: {
      paddingLeft: spacing(0),
      width: '100% !important',
    },
    [breakpoint.between('sm', 'md')]: {
      paddingLeft: spacing(0),
    },
    [breakpoint.between('md', 'lg')]: {
      paddingLeft: spacing(4),
    },
    [breakpoint.between('lg', 'xl')]: {
      paddingLeft: spacing(7.5),
    },
    [breakpoint.up('xl')]: {
      paddingLeft: spacing(37),
    },
  },
  video: {
    position: 'absolute',
    width: '93%',
    zIndex: 3,
  },
  playIcon: {
    position: 'absolute',
    zIndex: 4,
  },
  tablet: {
    width: '100%',
    zIndex: 0,
    borderRadius: 25,
    // Safari fix
    '& *': {
      borderRadius: 25,
    },
  },
  chapter: ({ variant }) => ({
    display: 'flex',
    alignItems: 'center',
    width: pxToRem(520),
    maxWidth: '100%',
    backgroundColor: variant === 'light'
      ? 'transparent'
      : 'rgba(0, 57, 96, 0.4)',
    borderRadius: spacing(15),
    padding: `${spacing(3)} ${spacing(3)}`,
    '&:hover': {
      backgroundColor: variant === 'light'
        ? 'rgba(232, 247, 248, 0.6)'
        : 'rgba(19, 38, 109, 0.6)',
    },
  }),
  activeChapter: ({ variant }) => ({
    backgroundColor: variant === 'light'
      ? 'rgba(232, 247, 248, 1)'
      : 'rgba(19, 38, 109, 1)',
  }),
  chapterIcon: {
    width: pxToRem(63),
    height: pxToRem(63),
    marginRight: spacing(6),
  },
});

interface VideoData {
  videoSrc: string,
  iconSrc: string,
  title: string,
  description: string,
}

const videoData: VideoData[] = [
  {
    videoSrc: assetLink('landing-ui/videos/MarketingCompressed.mp4'),
    iconSrc: assetLink('landing-ui/icons/MarketingIconAlt.svg'),
    title: 'Marketing Apps',
    description: 'Getting online & getting more customers',
  },
  {
    videoSrc: assetLink('landing-ui/videos/OperationsCompressed.mp4'),
    iconSrc: assetLink('landing-ui/icons/OperationsIcon.svg'),
    title: 'Operation Apps',
    description: 'Managing the day to day of the business',
  },
  {
    videoSrc: assetLink('landing-ui/videos/CommerceCompressed.mp4'),
    iconSrc: assetLink('landing-ui/icons/CommerceIcon.svg'),
    title: 'Commerce Apps',
    description: 'Accepting payments & making the sale',
  },
  {
    videoSrc: assetLink('landing-ui/videos/EmployeeManagementCompressed.mp4'),
    iconSrc: assetLink('landing-ui/icons/EmployeeManagementIcon.svg'),
    title: 'Employee Management Apps',
    description: 'Managing your team',
  },
  {
    videoSrc: assetLink('landing-ui/videos/BackOfficeCompressed.mp4'),
    iconSrc: assetLink('landing-ui/icons/BackOfficeIcon.svg'),
    title: 'Back Office Apps',
    description: 'Reporting, analytics, & recordkeeping',
  },
];

type Variant = 'light' | 'dark';

interface Props {
  variant?: Variant,
}

const AppVideos: React.FC<Props> = ({ variant = 'dark' }) => {
  const [hasLooped, setHasLooped] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoReady, setVideoReady] = useState(false);
  const isVisible = useIsVisible(videoRef);
  const [activeVideoIdx, setActiveVideoIdx] = useState(0);
  const [playing, setPlaying] = useState(false);
  const isMobile = useIsMobile();
  const {
    ref: videoContainerRef,
    handleScroll: scrollVideoIntoView,
  } = useScrollableRef();

  // iOS workaround to force the video to load
  useEffect(() => {
    videoRef.current?.load();
  }, [activeVideoIdx, videoRef]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.readyState > 2) {
      setVideoReady(true);
    }
  }, [activeVideoIdx]);

  useEffect(() => {
    if (playing) {
      videoRef.current?.play();
    }
  }, [playing, activeVideoIdx]);

  useEffect(() => {
    if (videoReady && isVisible && !hasLooped) {
      setPlaying(true);
    }
  }, [videoReady, isVisible, hasLooped]);

  const classes = useStyles({ variant });
  return (
    <SectionContainer className={cx(variant === 'dark' && classes.containerDark)}>
      <div style={{ width: '100%', marginBottom: spacing(10) }}>
        <WithUnderline centered>
          <Typography variant="h2" color={variant === 'light' ? 'blue' : 'white'} centered>
            Apps to take your business to the next level
          </Typography>
          <Typography variant="p1" color={variant === 'light' ? 'blackish' : 'white'} style={{ marginTop: pxToRem(20) }} centered>
            Easy to use apps to help you run as much or as little of your business as you want!
          </Typography>
        </WithUnderline>
      </div>
      <div className={classes.row}>
        <div className={classes.videoContainer} ref={videoContainerRef}>
          <div style={{
            position: 'absolute',
            backgroundColor: '#FFFFFF',
            width: '94%',
            height: '92%',
            borderRadius: 8,
            zIndex: 1,
          }}
          />
          <video
            controls={false}
            muted
            playsInline
            disableRemotePlayback
            src={videoData[activeVideoIdx].videoSrc}
            poster={`${videoData[activeVideoIdx].videoSrc}.thumbnail.jpg`}
            className={classes.video}
            ref={videoRef}
            onCanPlayThrough={() => {
              setVideoReady(true);
            }}
            onEnded={() => {
              if (activeVideoIdx === videoData.length - 1) {
                setActiveVideoIdx(0);
                setPlaying(false);
                setHasLooped(true);
              } else {
                setActiveVideoIdx(activeVideoIdx + 1);
              }
              setVideoReady(false);
            }}
          />
          <StaticImage
            src="https://assets.bizwise.com/landing-ui/video-containers/Tablet.png"
            className={classes.tablet}
            width={600}
            alt=""
          />
          {!playing && (
            <IconButton onClick={() => setPlaying(true)} className={classes.playIcon}>
              <img src={assetLink('landing-ui/icons/PlayIcon.svg')} alt="Play icon" />
            </IconButton>
          )}
        </div>
        <div className={classes.chapterContainer}>
          {videoData.map((data, i) => (
            <Clickable
              key={data.title}
              className={cx(classes.chapter, {
                [classes.activeChapter]: i === activeVideoIdx,
              })}
              onClick={() => {
                setActiveVideoIdx(i);
                setPlaying(true);
                if (isMobile) scrollVideoIntoView();
              }}
            >
              <img src={data.iconSrc} alt="" className={classes.chapterIcon} />
              <div>
                <Typography variant="p1" style={{ fontWeight: 600 }} color={variant === 'light' ? 'blackish' : 'white'}>
                  {data.title}
                </Typography>
                <Typography variant="p2" color={variant === 'light' ? 'blackish' : 'white'}>
                  {data.description}
                </Typography>
              </div>
            </Clickable>
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};

export default AppVideos;
