import { useEffect, useState } from 'react';

const defaultCopy = {
  headline1: '',
  headline2: '',
  ctaBtn: 'Get Started',
};

export function useHeroCopy() {
  const [copy, setCopy] = useState({
    headline1: '',
    headline2: '',
    ctaBtn: '',
  });
  useEffect(() => {
    async function init() {
      const referrerId = window.growsurf?.getReferrerId();
      if (referrerId) {
        window.growsurf?.getParticipantById(referrerId).then((referrer) => {
          if (referrer) {
            setCopy({
              headline1: `${referrer.firstName || referrer.email} Gave You $100`,
              headline2: 'To Spend at Bizwise',
              ctaBtn: 'Claim My Free $100',
            });
          } else {
            setCopy(defaultCopy);
          }
        });
      } else {
        setCopy(defaultCopy);
      }
    }

    if (!window.growsurf) {
      window.addEventListener('grsfReady', init);
      return () => window.removeEventListener('grsfReady', init);
    }
    init();
    return () => {};
  }, []);
  const loading = copy.headline1 === '';

  return { copy, loading };
}

export default useHeroCopy;
