import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import { assetLink } from 'src/utils';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import WebEditorVideo from 'src/components/WebEditorVideo';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    background: 'linear-gradient(99.96deg, #13266D 30.39%, #5FBCFF 153.01%) #F7F7F7',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(230.96deg, #13266D 30.39%, #5FBCFF 153.01%), #F7F7F7',
    },
  },
  content: {
    position: 'relative',
    height: '100%',
    width: '50%',
    [breakpoint.down('md')]: {
      width: '100%',
      marginTop: spacing(7.5),
    },
  },
  presentation: {
    height: '100%',
    width: '50%',
    [breakpoint.down('md')]: {
      width: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: spacing(1),
  },
  checkIcon: {
    marginTop: pxToRem(5),
    marginRight: spacing(6),
  },
});

const content1 = [
  'Beautifully designed',
  'Optimized for all devices',
  'Turbocharged with Search Engine Optimization',
  'Lightning fast speeds powered by Google',
  'Customized just for you and your business',
];
const content2 = [
  '24/7/365 support',
  'Support for your business every step of the way',
  'Constant updates, so while the tech world updates, your business does too!',
];

const Grow: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.root} backgroundShape={3}>
      <div className={classes.presentation}>
        <WebEditorVideo style={{ width: '85%' }} />
      </div>
      <div className={classes.content}>
        <Typography variant="h2" color="white" underline>
          Grow your business
        </Typography>
        <Typography variant="p1" color="white" style={{ marginTop: spacing(6) }}>
          We know your customers mean the world to you,
          {' '}
          so we make sure to take an individual approach to optimizing your business for you
          {' '}
          and your customers.
        </Typography>
        <Typography variant="h3" color="white" style={{ marginTop: spacing(8), marginBottom: spacing(4) }}>
          The perfect website to put your business ahead of the competition.
        </Typography>
        {content1.map((content) => (
          <div className={classes.item} key={content}>
            <img src={assetLink('CheckIcon.svg')} alt="Check Icon" className={classes.checkIcon} />
            <Typography variant="p2" color="white">
              {content}
            </Typography>
          </div>
        ))}
        <Typography variant="h3" color="white" style={{ marginTop: spacing(8), marginBottom: spacing(4) }}>
          We stay ahead of technology trends, so you don&apos;t have to
        </Typography>
        {content2.map((content) => (
          <div className={classes.item} key={content}>
            <img src={assetLink('CheckIcon.svg')} alt="Check Icon" className={classes.checkIcon} />
            <Typography variant="p2" color="white">
              {content}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default Grow;
