import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import HeroSection from 'src/sections/Referral/Hero';
import GrowSection from 'src/sections/shared/Grow';
import AppVideosSection from 'src/sections/shared/AppVideos';
import NextLevelSection from 'src/sections/Referral/NextLevel';
import RunYourBusinessSection from 'src/sections/Referral/RunYourBusiness';
import ApiWrapper from 'src/components/ApiWrapper';

const ReferralPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="Referrals - Bizwise" />
      <HeroSection />
      <RunYourBusinessSection />
      <GrowSection />
      <AppVideosSection variant="light" />
      <NextLevelSection />
    </Layout>
  </ApiWrapper>
);

export default ReferralPage;
